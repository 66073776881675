<template>
  <div>
    <div class="size-desktop full-page">
      <div class="container-top center">
        <div class="titles" :style="getColor">
          <div class="main-title">{{ selected.title }}</div>
          <div class="main-subtitle">{{ selected.subtitle1 }}</div>
          <div v-if="selected.subtitle2" class="main-subtitle">
            {{ selected.subtitle2 }}
          </div>
        </div>

        <div class="highlights-container">
          <div
            class="highlight-item"
            v-for="(descriptionItem, i) of selected.descriptions"
            :key="i"
          >
            {{ descriptionItem }}
          </div>
        </div>
      </div>
      <div class="container-bottom">
        <div
          v-for="project of projects"
          :key="project.name"
          :id="project.name + '-cover'"
          class="image-container"
          @mouseenter="selectProject(project.name)"
          @mouseleave="resetSelected()"
          @click="goTo(project.name)"
        >
          <img
            :src="
              require('../assets/images/projects/' +
                project.name +
                '/mockup.png')
            "
            alt=""
          />
        </div>
      </div>
    </div>
    <div ref="mainMobile" class="size-mobile full-page center">
      <div ref="titleMobile" class="main-title">My Projects</div>
      <div class="container-bottom-mobile">
        <div
          :id="projectMeandre.name + '-cover'"
          :class="selected.name === projectMeandre.name ? 'selected' : ''"
          :ref="projectMeandre.name"
          class="image-container"
          @click="selectProject(projectMeandre.name)"
        >
          <img
            :src="
              require('../assets/images/projects/' +
                projectMeandre.name +
                '/mockup.png')
            "
            alt=""
          />
        </div>
        <transition name="desc">
          <div
            v-if="selected.name === projectMeandre.name"
            :ref="projectMeandre.name + '-highlight'"
            class="selected-info"
            :style="[getColor, getSize]"
          >
            <div class="main-title">{{ selected.title }}</div>
            <div class="main-subtitle">{{ selected.subtitle1 }}</div>
            <PillText content="See project!" />
          </div>
        </transition>

        <div
          :id="projectHealthie.name + '-cover'"
          :class="selected.name === projectHealthie.name ? 'selected' : ''"
          :ref="projectHealthie.name"
          class="image-container"
          @click="selectProject(projectHealthie.name)"
        >
          <img
            :src="
              require('../assets/images/projects/' +
                projectHealthie.name +
                '/mockup.png')
            "
            alt=""
          />
        </div>
        <transition name="desc">
          <div
            v-if="selected.name === projectHealthie.name"
            :ref="projectHealthie.name + '-highlight'"
            class="selected-info"
            :style="getColor"
          >
            <div class="main-title">{{ selected.title }}</div>
            <div class="main-subtitle">{{ selected.subtitle1 }}</div>
            <PillText
              content="See project!"
              @click="goTo(projectHealthie.name)"
            />
          </div>
        </transition>

        <div
          :id="projectNotion.name + '-cover'"
          :class="selected.name === projectNotion.name ? 'selected' : ''"
          :ref="projectNotion.name"
          class="image-container"
          @click="selectProject(projectNotion.name)"
        >
          <img
            :src="
              require('../assets/images/projects/' +
                projectNotion.name +
                '/mockup.png')
            "
            alt=""
          />
        </div>
        <transition name="desc">
          <div
            v-if="selected.name === projectNotion.name"
            :ref="projectNotion.name + '-highlight'"
            class="selected-info"
            :style="getColor"
          >
            <div class="main-title">{{ selected.title }}</div>
            <div class="main-subtitle">{{ selected.subtitle1 }}</div>
            <PillText content="See project!" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
.full-page {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.container-top {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titles {
  margin-bottom: 1rem;
}

.highlights-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}
.container-bottom {
  max-height: 40%;
  display: flex;
}

.image-container {
  background-position: center;
  background-size: cover;
  filter: grayscale(1);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  &:first-child {
    img {
      max-height: 70%;
    }
  }
  &:not(:first-child) {
    padding-top: 2rem;
  }

  &:hover {
    filter: grayscale(0);
  }
}

#meandre-cover {
  background-image: url("../assets/images/projects/meandre/cover.png");
}
#healthie-cover {
  background-image: url("../assets/images/projects/healthie/cover.png");
}
#notion-cover {
  background-image: url("../assets/images/projects/notion/cover.png");
}

.container-bottom-mobile {
  flex: 1;

  .main-title {
    font-size: 2.5rem;
    margin: 0;
  }
  .image-container {
    position: relative;
    .float-btn {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
    &.selected {
      filter: grayscale(0);
    }
  }

  .selected-info {
    height: calc(((100vh - 50px - 16px - 92px - 32px) / 12) * 3.5);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    .pill-text {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .highlights-container {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  // * Description animations
  .desc-enter-from,
  .desc-leave-to {
    height: 0px;
  }

  .desc-enter-active,
  .desc-leave-active {
    overflow: hidden;
    transition: all 0.2s ease-in;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-leave-from,
  .fade-enter-to {
    opacity: 1;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-in;
  }
}

@media screen and (max-width: $screen-mobile) {
  .size-desktop {
    display: none;
  }
}

@media screen and (min-width: $screen-mobile + 1) {
  .size-mobile {
    display: none;
  }
}
</style>

<script>
import variables from "@/assets/scss/variables.scss";
import {
  projectHealthie,
  projectMeandre,
  projectNotion,
} from "@/helpers/content.js";
import PillText from "@/components/PillText.vue";

export default {
  name: "Projects",
  components: { PillText },
  data() {
    return {
      selected: null,
      default: {
        title: "My Projects",
        subtitle1:
          "the absorption of all colors and the absence of light & having no hue due to the reflection of all incident light",
        descriptions: ["Find the colors!"],
        color: null,
      },
      projects: [projectMeandre, projectHealthie, projectNotion],
      mainSize: null,
      pristine: true,
      projectMeandre,
      projectHealthie,
      projectNotion,
      sizeCoverLarge: 4,
      sizeCoverSmall: 2.25,
    };
  },
  created() {
    if (window.innerWidth <= variables.screenMobile) {
      this.selectProject(projectMeandre.name, true);
    } else {
      this.selected = this.default;
    }
  },
  mounted() {
    this.mainSize =
      this.$refs.mainMobile.clientHeight -
      this.$refs.titleMobile.clientHeight -
      variables.baseFontSize * 3;

    this.$refs[projectHealthie.name].style.height =
      (this.mainSize / 12) * this.sizeCoverSmall + "px";
    this.$refs[projectMeandre.name].style.height =
      (this.mainSize / 12) * this.sizeCoverSmall + "px";
    this.$refs[projectNotion.name].style.height =
      (this.mainSize / 12) * this.sizeCoverSmall + "px";

    if (this.selected && this.selected.name) {
      this.$refs[this.selected.name].style.height =
        (this.mainSize / 12) * this.sizeCoverLarge + "px";
    }
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    selectProject(projectName, creating = false) {
      let selectedOld = this.selected ? this.selected.name : null;
      this.selected = this.projects.find(
        (project) => project.name === projectName
      );

      if (!creating) {
        if (this.pristine) {
          this.$refs[projectHealthie.name].style.transition =
            "height 0.2s ease-in";
          this.$refs[projectMeandre.name].style.transition =
            "height 0.2s ease-in";
          this.$refs[projectNotion.name].style.transition =
            "height 0.2s ease-in";

          this.pristine = false;
        }

        if (selectedOld) {
          this.$refs[selectedOld].style.height =
            (this.mainSize / 12) * this.sizeCoverSmall + "px";
        }
        this.$refs[projectName].style.height =
          (this.mainSize / 12) * this.sizeCoverLarge + "px";
      }
    },
    resetSelected() {
      this.selected = this.default;
    },
  },
  computed: {
    getColor() {
      return this.selected.color ? `color: ${this.selected.color}` : null;
    },
  },
};
</script>
