<template>
  <div class="container-page">
    <ScrollButton :text="scrollText" @click="scrollTo" />
    <div id="background">
      <img id="curve" src="../../assets/images/black-curve.svg" />
    </div>

    <div id="top" class="project-container" @scroll="scrollHandler">
      <div class="main-title">{{ project.title }}</div>
      <div class="main-subtitle">{{ project.subtitle }}</div>
      <div class="main-descriptions">
        <div v-for="desc of project.descriptions" :key="desc">
          {{ desc }}
        </div>
      </div>
      <div class="mockup-display">
        <img src="../../assets/images/projects/taxymatch/cover-1.png" alt="" />

        <img src="../../assets/images/projects/taxymatch/cover-2.svg" alt="" />
        <img src="../../assets/images/projects/taxymatch/cover-3.svg" alt="" />
      </div>

      <div class="project-content">
        <div class="project-section">
          <div class="title">Booking a shared ride flow</div>
          <div class="subtitle">Overview</div>
          <div class="list-flex">
            <div class="list-item item-short">
              <div class="item-title">Must haves</div>
              <div class="item-text">Enter departure and arrival</div>
              <div class="item-text">
                Communicate the neccessary information
              </div>
              <div class="item-text">
                Encourage users to choose to share the ride
              </div>
            </div>
            <div class="list-item item-short">
              <div class="item-title">Details</div>
              <div class="item-text">Hi-fi | 14 days | Figma</div>
              <div class="item-text">Mobile app</div>
              <div class="item-text">
                Work with the CEO & CTO of the company
              </div>
            </div>
            <div class="list-item item-short">
              <div class="item-title">My role</div>
              <div class="item-text">UX/UI designer</div>
            </div>
          </div>
        </div>

        <div id="section-client" class="project-section">
          <div class="title">TaxyMatch</div>
          <div class="subtitle">My Client</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                A startup company that provides a
                <strong> shared taxi experience</strong>
                without losing too much time!
              </div>
              <div class="item-text">
                Even if it is possible to order an entire taxi, TaxyMatch’s goal
                is to make transportation available for all by sharing the taxi
                and spliting the price and make a greener choice!
              </div>
            </div>
            <div class="grid-image">
              <img
                id="logo"
                src="../../assets/images/projects/taxymatch/logo.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">My approach</div>
          <div class="subtitle">Design thinking process</div>
          <div id="our-approach" class="list-grid list-5">
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-dots.svg"
                alt=""
              />
              <div class="item-title">Empathise</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-vertical.svg"
                alt=""
              />
              <div class="item-title">Define</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-random.svg"
                alt=""
              />
              <div class="item-title">Ideate</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-snake.svg"
                alt=""
              />
              <div class="item-title">Prototype</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-eyes.svg"
                alt=""
              />
              <div class="item-title">Test</div>
            </div>
          </div>

          <div id="our-approach-mobile">
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-dots.svg"
                alt=""
              />
              <div class="item-title">Empathise</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-vertical.svg"
                alt=""
              />
              <div class="item-title">Define</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-random.svg"
                alt=""
              />
              <div class="item-title">Ideate</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-snake.svg"
                alt=""
              />
              <div class="item-title">Prototype</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-eyes.svg"
                alt=""
              />
              <div class="item-title">Test</div>
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">How did I tackle this project?</div>
          <div class="subtitle">Methodologies and methods I used</div>
          <div class="list-flex list-large">
            <div class="list-item">
              <div class="item-title">Diverge</div>
              <div class="item-text">User interviews</div>
              <div class="item-text">Surveys</div>
              <div class="item-text">Heuristic evaluation</div>
              <div class="item-text">Usability test</div>
              <div class="item-text">Secondary research</div>
              <div class="item-text">Market research</div>
            </div>
            <div class="list-item">
              <div class="item-title">Converge</div>
              <div class="item-text">Affinity diagram</div>
              <div class="item-text">Empathy map</div>
              <div class="item-text">HMWS</div>
              <div class="item-text">User persona</div>
              <div class="item-text">Problem statement</div>
              <div class="item-text">User journey</div>
            </div>
            <div class="list-item">
              <div class="item-title">Diverge</div>
              <div class="item-text">Brainstorming</div>
              <div class="item-text">Crazy 8</div>
              <div class="item-text">Sketching</div>
              <div class="item-text">Concept testing</div>
              <div class="item-text">Low-fi wireframes</div>
              <div class="item-text">Style tile</div>
              <div class="item-text">Mid-fi prototyping</div>
              <div class="item-text">Hi-fi prototyping</div>
            </div>
            <div class="list-item">
              <div class="item-title">Converge</div>
              <div class="item-text">Heuristic evaluation</div>
              <div class="item-text">Usability testing</div>
              <div class="item-text">Desirability testing</div>
              <div class="item-text">Accessibility testing</div>
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Where did I begin?</div>
          <div class="subtitle">Kick-off meeting</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                My first step was to do some research about the company.I
                learned many things about TaxyMatch which allowed me to focus
                more on the details of the challenge they provided me during the
                meeting.
              </div>

              <div class="item-text highlight">
                <strong>At the end of the meeting I had understood:</strong>
                <br />
                what goes wrong in the flow all the details and conditions about
                sharing a taxi ride advantages of TaxyMatch compared to smilar
                services needs of the stakeholders
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/laptop.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-condition" class="project-section">
          <div class="title">How does it actually work?</div>
          <div class="subtitle">Condition to share a ride</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                On the TaxyMatch application, there are some
                <strong>pre-defined departure / arrival addresses.</strong> We
                called them hotspots, such as airports, museums, concert
                halls...
              </div>
              <div class="item-text top-2">
                Users can choose to <strong>share</strong> their ride, if
                <strong
                  >at least one address in their iterenary is a hotspot.</strong
                >
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/spot.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Why should people share a taxi ride anyways?</div>
          <div class="subtitle">Advantages of sharing a taxi ride</div>
          <div class="list-grid list-2-3">
            <div class="color-primary">
              <div class="item-text">
                It is cheaper, makes taxi available to everybody
              </div>
              <div class="item-text">Environmentally friendly</div>
              <div class="item-text">Less traffic jams</div>
              <div class="item-text">Fair payment to drivers</div>
            </div>
          </div>
        </div>

        <img
          id="cars-paris-desktop"
          class="full top-2"
          src="../../assets/images/projects/taxymatch/cars-paris-desktop.png"
          alt=""
        />

        <img
          id="cars-paris-mobile"
          class="full top-2"
          src="../../assets/images/projects/taxymatch/cars-paris-mobile.png"
          alt=""
        />

        <div class="project-section">
          <div class="title">What do I know, what am I looking for?</div>
          <div class="subtitle">CSD Board</div>
          <div class="list-grid list-2-3">
            <div>
              <div class="item-text">
                My next steps will be confirming my supposition and finding
                answers to my doubts
              </div>
            </div>
          </div>
        </div>

        <div class="top-1">
          <img
            id="csd-board-desktop"
            class="full"
            src="../../assets/images/projects/taxymatch/csd-board-desktop.svg"
            alt=""
          />

          <img
            id="csd-board-mobile"
            class="full"
            src="../../assets/images/projects/taxymatch/csd-board-mobile.svg"
            alt=""
          />
        </div>

        <div class="project-section">
          <div class="title">What I also know</div>
          <div class="subtitle">Client Brief</div>
          <div class="list-grid list-2">
            <div class="color-primary">
              <div class="item-text">
                Make users understand all the details and conditions about
                sharing a ride
              </div>
              <div class="item-text">Encourage them to share a ride</div>
              <div class="item-text">
                Help users be able to book a taxi ride from another city
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/client-brief.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Understand what users do</div>
          <div class="subtitle">Survey</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text color-primary">
                I started with a survey to understand what users do in the
                situations where we think our users need our service
              </div>
              <div class="item-text top-2">
                I made my own preperation on Figjam, I called it “survey tree”,
                then created it using Google Forms
              </div>
              <div class="item-text">
                <a
                  href="https://forms.gle/p2RzTrzGj31igLkh6"
                  target="_blank"
                  class="bold"
                  >See the survey in Google Forms</a
                >
              </div>
              <div class="item-text">
                <a
                  href="https://www.figma.com/file/C8fGZqQh71l93mZUTH6vKf/Survey-tree?node-id=0%3A1"
                  target="blank"
                  class="bold"
                  >See the complete survey tree</a
                >
              </div>
            </div>
            <div class="grid-image hide-mobile">
              <img
                src="../../assets/images/projects/taxymatch/stats.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="top-4 center">
          <img
            style="max-width: 500px"
            src="../../assets/images/projects/taxymatch/survey-text.png"
            alt=""
            class="full"
          />
        </div>

        <div id="section-interviews" class="project-section">
          <div class="title">Why, why, why & how?</div>
          <div class="subtitle">User interviews</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                With interviews,
                <strong
                  >my goal was to understand the why behind peoples behaviours
                  and decisions.</strong
                >
              </div>
              <div class="item-text">
                Identify the pain points people can encounter while sharing
                their taxi
              </div>
              <div class="item-text">
                Motivations behind people’s decisions to choose to share a taxi
              </div>
              <div class="item-text">
                Motivations behind people’s decisions when they prefer not to
                share a taxi
              </div>
              <div class="item-text">
                How do they feel about TaxyMatch service
              </div>
              <div class="item-text top-2">
                I prepared this
                <a
                  href="https://docs.google.com/document/d/1jzuhGovxVmjWYWV7sNEinD3SXT2YjpzDlxE0kiwKY3E/edit?usp=sharing"
                  target="blank"
                  class="bold"
                  >interview guide</a
                >, and did interviews with
                <strong>4 potential users.</strong> TaxyMatch team also
                conducted <strong>3 user interviews,</strong> following my
                interview guide, with the users who already used TaxyMatch
                service.
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/user-interviews.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">What I found from all the user research</div>
          <div class="subtitle">Affinity diagram</div>
          <div class="list-grid list-2-3">
            <div>
              <div class="item-text">
                I put all of my findings from the survey, interviews and the
                secondary research, and grouped the findings according to their
                relevances
              </div>
              <div class="item-text">
                Following, I will explain the important parts of the affinity
                diagram and make a summary of my insights
              </div>
              <div class="item-text">
                <a
                  href="https://www.figma.com/file/34ueBeZ3cjkB3IKgInCNzn/Affinity-diagram?node-id=0%3A1"
                  target="blank"
                  class="bold"
                  >See the affinity diagram close up here</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="top-1">
          <img
            src="../../assets/images/projects/taxymatch/affinity-diagram.png"
            alt=""
            class="full"
          />
        </div>

        <div id="section-insights" class="project-section">
          <div class="title">About taxis and transportation</div>
          <div class="subtitle">Insights summary</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                People use various types of transportations but in certain
                conditions, <strong>they mostly prefer Taxi & VTC:</strong>
              </div>
              <div class="item-text">
                From my survey results; late at night <strong>58%</strong> of 40
                respondants take a VTC and <strong>65%</strong> take a taxi. The
                situation is very smilar to go to the airport.
              </div>
              <div class="item-text top-2">
                But interview results show that people are not so happy with
                this even though they feel obligated to take a VTC/Taxi in those
                situations.
              </div>
              <div class="item-text">
                <div class="bold">The biggest problem is the price!</div>
              </div>
              <div class="item-text">
                80% of my interviewees consider the <strong>time</strong> that
                it will take while choosing a transportation and 60% think the
                <strong>price</strong> is also important.
              </div>
            </div>
            <div class="grid-image">
              <img
                class="full"
                src="../../assets/images/projects/taxymatch/insights-summary.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Current application evaluation</div>
          <div class="subtitle">Heuristic evaluation of the existing app</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                I evaluated the existing app according to the 10
                <strong>Neilsen’s Heuristics.</strong>
              </div>
              <div class="item-text">
                My goal was to understand the interface more, have a better
                understanding of the problems coming from the interface. These
                findings also helped me define the problem.
              </div>
              <div class="item-text">
                Here is an example of my evaluation on rate page of the app,
                according to me the most important part of the flow.
              </div>
            </div>
            <div class="grid-image hide-mobile">
              <img
                src="../../assets/images/projects/taxymatch/heuristic-evaluation-1.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="top-1 center">
          <img
            src="../../assets/images/projects/taxymatch/heuristic-evaluation-2.svg"
            alt=""
            class="full"
            style="max-width: 800px"
          />
        </div>

        <div class="project-section">
          <div class="title">What do users do with the current version?</div>
          <div class="subtitle">Usability test</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                I conducted usability tests with 5 users who never used this
                application before
              </div>
              <div class="item-text">
                I gave them different tasks, book a taxi to go to the airport,
                book a taxi from a museum to go home...
                <strong>And I asked them to share the ride</strong>
              </div>
              <div class="item-text">
                At the end,
                <strong
                  >I asked them if they are sharing the ride or not, all of them
                  had doubts</strong
                >
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/usability-speak.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-scoped" class="project-section">
          <div class="title">Scoped down problems</div>
          <div class="subtitle">How Might We Statements & insights</div>
          <div class="list-grid list-2-3">
            <div>
              <div class="item-text">
                <strong>Delays</strong> might be a block for sharing a taxi
              </div>
              <div class="item-text">
                People <strong>care</strong> a lot about the
                <strong>price while choosing</strong> VTC / Taxi
              </div>
              <div class="item-text">
                People think that sharing their ride is
                <strong>not so reliable in terms of time</strong>
              </div>
              <div class="item-text">
                People have a few applications to
                <strong>compare prices and waiting time</strong>
              </div>
              <div class="item-text">
                People need to have <strong>more information</strong> about
                timing in advance to make their decisions
              </div>
            </div>
          </div>
        </div>

        <div class="top-2">
          <img
            id="how-might-desktop"
            class="full"
            src="../../assets/images/projects/taxymatch/how-might-desktop.svg"
            alt=""
          />

          <img
            id="how-might-mobile"
            class="full"
            src="../../assets/images/projects/taxymatch/how-might-mobile.svg"
            alt=""
          />
        </div>

        <div id="section-persona" class="project-section">
          <div class="title">Representative TaxyMatch user</div>
          <div class="subtitle">User persona</div>
        </div>

        <div class="top-2">
          <img
            src="../../assets/images/projects/taxymatch/user-persona-desktop.png"
            id="persona-desktop"
            alt=""
            class="full"
          />

          <img
            src="../../assets/images/projects/taxymatch/user-persona-mobile.png"
            id="persona-mobile"
            alt=""
            style="max-width: 800px"
            class="full"
          />
        </div>

        <div id="section-journey" class="project-section">
          <div class="title">
            What is going on currently while Celine is trying to book a shared
            taxi?
          </div>
          <div class="subtitle">User journey</div>
          <div class="item-text color-primary">
            Scroll horizontally to see the full journey!
          </div>

          <div class="scroll-container">
            <div class="scroll-image">
              <img
                src="../../assets/images/projects/taxymatch/user-journey.svg"
              />
            </div>
          </div>
        </div>

        <div id="section-problem" class="project-section">
          <div class="title">To make things more clear</div>
          <div class="subtitle">Problem statement</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                People who
                <strong
                  >need to transport using VTC / taxi need a way to find the
                  cheapest and the quickest</strong
                >
                option.
              </div>
              <div class="item-text">
                Because, they are often
                <strong
                  >frustrated for paying too much money or spending too much
                  time</strong
                >
                to reach to their destination
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/problem-statement.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">On the other hand</div>
          <div class="subtitle">Business Needs statement</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                TaxyMatch need their <strong>users to understand</strong> that
                they can <strong>pay less</strong> and still
                <strong
                  >reach their destination in a reasonable amount of
                  time.</strong
                >
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/business-needs.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">A few ideas to start with</div>
          <div class="subtitle">Solution</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                Help them understand their gain: change the payment system
              </div>
              <div class="item-text">
                The part where users need to make decisions should be more
                simple and clear. Don’t overwhelm users with lots of information
              </div>
              <div class="item-text">
                Make the interface more minimal, highlight important information
              </div>
            </div>
            <div class="grid-image hide-mobile">
              <img
                src="../../assets/images/projects/taxymatch/solution.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">A new touch</div>
          <div class="subtitle">Style tile</div>
        </div>

        <div class="top-2">
          <img
            id="styles-desktop"
            class="full"
            src="../../assets/images/projects/taxymatch/style-tile-desktop.png"
            alt=""
          />

          <img
            id="styles-mobile"
            class="full"
            src="../../assets/images/projects/taxymatch/style-tile-mobile.png"
            alt=""
          />
        </div>

        <div id="section-hifi-1" class="project-section">
          <div class="title">Coming home from friend’s house</div>
          <div class="subtitle">Hi-fi prototype</div>
          <div class="list-grid list-2">
            <div>
              <div class="color-primary">
                <div class="item-text">
                  I minimised the interface in order to highlight the important
                  information.
                </div>
                <div class="item-text">
                  Started the departure as the currrent location of the users.
                  On the step of choosing the arrival, users have the
                  possibility to change the departure address too.
                </div>
                <div class="item-text">
                  With the primary color, I aimed to highlight the flow that
                  leads to user’s goal.
                </div>
              </div>
              <div class="top-4">
                <div class="item-text">
                  In the small video, you can see Celine booking a ride to go
                  back from a friends house to her house.
                </div>
                <div class="item-text top-1">
                  Do you remember the rule of having at least one hotspot in the
                  iterenary to be able to share the ride?
                </div>
                <div class="item-text">
                  With the addresses she put, she can’t share the ride.
                </div>
                <div class="item-text color-primary top-2">
                  What I did in this case is to allow her to see that, with
                  little modifications she can actually share the ride. And the
                  choice is hers!
                </div>
              </div>
            </div>
            <div class="phone-image">
              <img
                src="../../assets/images/projects/taxymatch/high-fi-prototype-1.gif"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">How is this re-design better?</div>
          <div class="subtitle">Highlights</div>
          <div
            class="size-desktop list-grid images-grid"
            style="grid-template-columns: repeat(3, 1fr)"
          >
            <div class="list-flex flex-column">
              <div class="item-container">
                <div class="item-title">Communicating Hotspots</div>
                <div class="item-text">
                  One of the most complicated part was to make users understand
                  the conditions of sharing a ride.
                </div>
                <div class="item-text">
                  Instead of giving them lots of information to read through, I
                  chose to give them options when necessary like in the example.
                </div>
              </div>
              <div class="item-container">
                <div class="item-title">More details</div>
                <div class="item-text">
                  Users needed more details to be able to make a choice like
                  sharing a ride. By sliding, or tapping on the arrow, they can
                  see so much more information related to the option.
                </div>
              </div>
            </div>
            <img
              src="../../assets/images/projects/taxymatch/highlights-1.svg"
              alt=""
            />
            <img
              src="../../assets/images/projects/taxymatch/highlights-2.svg"
              alt=""
            />
          </div>
          <div class="size-medium">
            <div class="descriptions">
              <div class="item-container">
                <div class="item-title">Communicating Hotspots</div>
                <div class="item-text">
                  One of the most complicated part was to make users understand
                  the conditions of sharing a ride.
                </div>
                <div class="item-text">
                  Instead of giving them lots of information to read through, I
                  chose to give them options when necessary like in the example.
                </div>
              </div>
              <div class="item-container">
                <div class="item-title">More details</div>
                <div class="item-text">
                  Users needed more details to be able to make a choice like
                  sharing a ride. By sliding, or tapping on the arrow, they can
                  see so much more information related to the option.
                </div>
              </div>
            </div>
            <div class="images">
              <img
                src="../../assets/images/projects/taxymatch/highlights-1.svg"
                alt=""
              />
              <img
                src="../../assets/images/projects/taxymatch/highlights-2.svg"
                alt=""
              />
            </div>
          </div>
          <div class="size-mobile">
            <div class="item-container">
              <div class="item-title">Communicating Hotspots</div>
              <div class="item-text">
                One of the most complicated part was to make users understand
                the conditions of sharing a ride.
              </div>
              <div class="item-text">
                Instead of giving them lots of information to read through, I
                chose to give them options when necessary like in the example.
              </div>
            </div>

            <div class="item-container">
              <img
                src="../../assets/images/projects/taxymatch/highlights-1.svg"
                alt=""
              />
            </div>
            <div class="item-container">
              <div class="item-title">More details</div>
              <div class="item-text">
                Users needed more details to be able to make a choice like
                sharing a ride. By sliding, or tapping on the arrow, they can
                see so much more information related to the option.
              </div>
            </div>
            <div class="item-container">
              <img
                src="../../assets/images/projects/taxymatch/highlights-2.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-hifi-2" class="project-section">
          <div class="title">Booking to go to the airport next week</div>
          <div class="subtitle">Hi-fi prototype</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                In this scenario, Celine is booking a ride to go to the airport
                for next week.
              </div>
              <div class="item-text">
                She chooses the airport, the drop off terminal, date & time and
                finally she chooses to share the ride.
              </div>
              <div class="item-text">
                Since there is more time in the scenario, the application keeps
                searching for other passengers during this time.
              </div>
              <div class="item-text">
                Celine will get notified and the status will change on the
                interface when we find other passengers for this ride.
              </div>
            </div>
            <div class="phone-image">
              <img
                src="../../assets/images/projects/taxymatch/high-fi-prototype-2.gif"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Price problem</div>
          <div class="subtitle">Highlight their gain</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">TaxyMatch provides</div>
              <div class="impact-small">
                <div class="item-text">
                  <div class="impact">20% discount</div>
                  <div>with shared option</div>
                </div>
                <div class="item-text">
                  <div class="impact">40% discount</div>
                  <div>matched with another passenger</div>
                </div>
              </div>
              <div class="item-text top-1">
                <div class="bold">
                  Before users paid the cheapest price, and in case nobody
                  matched, they got withdrawn the difference.
                </div>
              </div>
              <div class="item-text">
                They felt disappointed and fooled even though this was
                indicated.
              </div>
              <div class="top-3">
                <div class="item-text color-primary">
                  <div class="bold">
                    What I did is to put forward the price that is cheaper than
                    the “full vehicle” option. And also put the information
                    about the possible discount in case there are matches.
                  </div>
                </div>
                <div class="item-text top-1">
                  This stops people being disappointed, and make them understand
                  better that they gained more discount by finding a match!
                </div>
              </div>
            </div>
            <div class="phone-image">
              <img
                src="../../assets/images/projects/taxymatch/highlight-their-gain.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-evaluation" class="project-section">
          <div class="title">2 weeks of evaluation</div>
          <div class="subtitle">Before, low-fi, mid-fi, hi-fi</div>
          <div class="list-grid list-2-3">
            <div>
              <div class="item-text">
                Here is the part where users got the most confused.
              </div>
              <div class="item-text">
                Too many decisions to take at once, so I seperated them into
                smaller steps.
              </div>
              <div class="item-text">
                The main choice to make is if they want to share the ride or
                not.
              </div>
              <div class="item-text color-primary">
                At each step, I did usability tests and iterated on my design
                with the results.
              </div>
            </div>
          </div>
          <div class="list-grid list-4 top-2">
            <div class="txt-img">
              <img
                class="full"
                id="existing-app"
                src="../../assets/images/projects/taxymatch/existing-app.png"
                alt=""
              />
              <div>TaxyMatch Existing App</div>
            </div>

            <div class="txt-img">
              <img
                class="full"
                src="../../assets/images/projects/taxymatch/low-fi.svg"
                alt=""
              />
              <div>Low-fi</div>
            </div>

            <div class="txt-img">
              <img
                class="full"
                src="../../assets/images/projects/taxymatch/mid-fi.svg"
                alt=""
              />
              <div>Mid-fi</div>
            </div>

            <div class="txt-img">
              <img
                class="full"
                src="../../assets/images/projects/taxymatch/final-fi.svg"
                alt=""
              />
              <div>Final version</div>
            </div>
          </div>
        </div>

        <div id="section-usability" class="project-section">
          <div class="title">What wasn’t clear?</div>
          <div class="subtitle">Usability test insights</div>
          <div class="list-grid list-2-3">
            <div>
              <div class="item-text">
                The option to change the departure/arrival in order to share the
                ride was too smilar to the component that didnt require any
                modification.
              </div>
              <div class="item-text top-2">
                So users didn’t really notice that by choosing this option, they
                were making changes in their iterinary.
              </div>
              <div class="item-text">As a solution:</div>
              <div class="item-text">
                I changed the <strong>UX writing</strong>
              </div>
              <div class="item-text">
                Introduced a <strong>new color</strong> to the flow, to get the
                attention of the user ( like: something different is going on,
                look there is a different color)
              </div>
              <div class="item-text">
                added so much <strong>more information</strong> about the new
                suggested itirenary.
              </div>
            </div>
          </div>
        </div>

        <div class="top-2">
          <img
            id="usability-test-desktop"
            class="full"
            src="../../assets/images/projects/taxymatch/usability-insights-desktop.svg"
            alt=""
          />

          <img
            id="usability-test-mobile"
            class="full"
            src="../../assets/images/projects/taxymatch/usability-insights-mobile.svg"
            alt=""
          />
        </div>

        <div class="project-section">
          <div class="title">What users thought?</div>
          <div class="subtitle">Desirability test</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                I conducted a desirability test and put together 20 words mix of
                what I aimed for the new interface and opposites of them.
              </div>
              <div class="item-text">
                As a result, users thought the new interface was
                <strong>clean, easy to use</strong> and
                <strong>convenient!</strong>
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/taxymatch/desirability.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">What I learned</div>
          <div class="subtitle">Key takeaways</div>
          <div class="list-grid list-2">
            <div class="color-primary">
              <div class="item-text">
                I improved my skills on collaboration since I worked directly
                both with CEO & CTO of the company
              </div>
              <div class="item-text top-1">
                Experienced and learned how to step back from an existing
                solution to get a broader understanding of the situation in
                order to stay innovative.
              </div>
              <div class="item-text top-1">
                I learned how to put together different findings such as
                usability test, interviews, surveys, heuristic evaluations, to
                get to one problem definition.
              </div>
              <div class="item-text top-1">
                Finishing a project like this in 2 weeks requires lots of
                organization and dedication
              </div>
            </div>
            <div class="grid-image hide-mobile">
              <img
                src="../../assets/images/projects/taxymatch/takeaways.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// export default {
//   name: "Taxymatch",
// };
import { projectTaxyMatch } from "@/helpers/content.js";
import variables from "@/assets/scss/variables.scss";
import ScrollButton from "@/components/ScrollButton.vue";
export default {
  name: "Taxymatch",
  components: {
    ScrollButton,
  },
  data() {
    return {
      project: projectTaxyMatch,
      // ! Sections need to be in the same order in this.scections and in the html
      sections: [
        {
          name: "section-client",
          title: "My Client",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-condition",
          title: "Condition to share a ride",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-interviews",
          title: "User interviews",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-insights",
          title: "Insights summary",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-scoped",
          title: "How Might We Statements & insights",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-persona",
          title: "User persona",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-journey",
          title: "User journey",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-problem",
          title: "Problem statement",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-hifi-1",
          title: "Hi-fi 1",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-hifi-2",
          title: "Hi-fi 2",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-evaluation",
          title: "Before, low-fi, mid-fi, hi-fi",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-usability",
          title: "Usability test insights",
          action: "Jump to",
          offset: null,
        },
      ],
      nextSection: {
        name: "section-client",
        title: "My Client",
        offset: null,
        action: "Jump to",
      },
      topSection: {
        name: "top",
        title: "Top",
        action: "Back to",
      },
      prevTime: Date.now(),
      scrollTimeout: null,
      variables,
    };
  },
  created() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  mounted() {
    this.scrollTop();
    this.setNextSection();
  },
  methods: {
    scrollHandler() {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.setNextSection();
      }, 100);
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    scrollTo() {
      if (this.nextSection.name === "top") {
        this.scrollTop();
      } else {
        const el = document.getElementById(this.nextSection.name);
        window.scrollTo({ top: el.offsetTop, behavior: "smooth" });
      }
      this.setNextSection();
    },
    setNextSection() {
      this.sections.forEach((section) => {
        const el = document.getElementById(section.name);
        section.offset = el.offsetTop;
      });
      let next;
      for (const section of this.sections) {
        if (window.scrollY + 5 < section.offset) {
          next = section.name;
          break;
        }
      }
      const totalScroll = window.scrollY + window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const errorMargin = 200;
      console.log("next", next);
      if (totalScroll + errorMargin >= documentHeight) {
        this.nextSection = this.topSection;
        return;
      }
      if (next) {
        this.nextSection = this.sections.find(
          (section) => section.name === next
        );
      } else {
        this.nextSection = this.topSection;
      }
    },
  },
  computed: {
    scrollText() {
      return this.nextSection.action + " " + this.nextSection.title;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/project.scss";
#background {
  background-color: $color-taxymatch-bg;
}
.main-title {
  color: $color-taxymatch-primary;
}
.main-subtitle {
  color: $color-taxymatch-secondary;
}
.item-title {
  color: $taxymatch-primary;
}
.highlight {
  color: $taxymatch-primary;
}
.color-primary {
  color: $taxymatch-primary;
}

.scroll-background {
  width: 2000px;
  height: 700px;
  background-image: url("../../assets/images/projects/taxymatch/user-journey.svg");
}
.scroll-container {
  overflow-x: scroll;
  .scroll-image {
    width: 2000px;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.txt-img {
  div {
    text-align: center;
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

#existing-app {
  width: 100%;
  height: auto;
}

@media screen and (min-width: $screen-mobile) {
  #existing-app {
    height: calc(100% - 2rem);
    width: auto;
  }

  #logo {
    max-width: 300px;
  }

  .scroll-image {
    width: 1800px;
  }
}
</style>
