<template>
  <div class="pill-text" :style="setBackground">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: "PillText",
  props: {
    content: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      default: "",
    },
  },
  computed: {
    setBackground() {
      return this.background
        ? {
            background: this.background,
          }
        : {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables.scss";
.pill-text {
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.3);
  width: fit-content;
  padding: 8px 32px;
  margin: 8px 8px;
  border-radius: 100px;
  text-align: center;
}
</style>
