<template>
  <div class="container-page">
    <ScrollButton :text="scrollText" @click="scrollTo" />

    <div id="background">
      <img id="curve" src="../../assets/images/black-curve.svg" />
    </div>

    <div class="project-container" @scroll="scrollHandler">
      <div class="main-title">{{ project.title }}</div>
      <div class="main-subtitle">{{ project.subtitle }}</div>
      <div class="main-descriptions">
        <div v-for="desc of project.descriptions" :key="desc">
          {{ desc }}
        </div>
      </div>
      <div class="mockup-display">
        <img
          src="../../assets/images/projects/healthie/cover-recipe-page.png"
          alt=""
        />

        <img
          src="../../assets/images/projects/healthie/cover-home-page.png"
          alt=""
        />
        <img
          src="../../assets/images/projects/healthie/cover-profile-page.png"
          alt=""
        />
      </div>

      <div class="project-content">
        <div class="project-section">
          <div class="title">Design an mHealth application</div>
          <div class="subtitle">Overview</div>
          <div class="list-flex">
            <div class="list-item item-short">
              <div class="item-title">Must haves</div>
              <div class="item-text">Set up a profile</div>
              <div class="item-text">Set goals</div>
              <div class="item-text">Track progress</div>
              <div class="item-text">Edit, share or delete data</div>
            </div>
            <div class="list-item item-short">
              <div class="item-title">Details</div>
              <div class="item-text">MVP | 10 days | Figma</div>
              <div class="item-text">Responsive mobile app</div>
              <div class="item-text">Team of 3 designers</div>
            </div>
            <div class="list-item item-short">
              <div class="item-title">My role</div>
              <div class="item-text">UX designer</div>
              <div class="item-text">UI designer</div>
              <div class="item-text">Interaction designer</div>
              <div class="item-text">UX writer</div>
            </div>
          </div>
        </div>

        <div id="section-client" class="project-section">
          <div class="title">The Daily Health Conference</div>
          <div class="subtitle">Our fictional client</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                Non-profit organization promoting
                <strong>health and wellness</strong>
              </div>
              <div class="item-text">
                Wants to see how <strong>technology</strong> can be used to
                <strong>help people live healthier lives</strong>
              </div>
              <div class="item-text">
                The mobile app is their membership offering to
                <strong>all members</strong>
              </div>
              <div class="item-text">
                could use their apps to keep track of diet plans, fitness
                programs, mental wellness, stress levels, water intake, and so
                on. The possibilities are endless and
                <strong>they are not exactly sure where to begin!</strong>
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/healthie/plant.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Our approach</div>
          <div class="subtitle">Design thinking process</div>
          <div id="our-approach" class="list-grid list-5">
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-dots.svg"
                alt=""
              />
              <div class="item-title">Empathise</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-vertical.svg"
                alt=""
              />
              <div class="item-title">Define</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-random.svg"
                alt=""
              />
              <div class="item-title">Ideate</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-snake.svg"
                alt=""
              />
              <div class="item-title">Prototype</div>
            </div>
            <div class="center">
              <img
                src="../../assets/images/projects/squiggle-eyes.svg"
                alt=""
              />
              <div class="item-title">Test</div>
            </div>
          </div>

          <div id="our-approach-mobile">
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-dots.svg"
                alt=""
              />
              <div class="item-title">Empathise</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-vertical.svg"
                alt=""
              />
              <div class="item-title">Define</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-random.svg"
                alt=""
              />
              <div class="item-title">Ideate</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-snake.svg"
                alt=""
              />
              <div class="item-title">Prototype</div>
            </div>
            <div class="list-item">
              <img
                src="../../assets/images/projects/squiggle-eyes.svg"
                alt=""
              />
              <div class="item-title">Test</div>
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">How we tackled this project</div>
          <div class="subtitle">Methodologies and methods we used</div>
          <div class="list-flex list-large">
            <div class="list-item">
              <div class="item-title">Diverge</div>
              <div class="item-text">User interviews</div>
              <div class="item-text">SME interview</div>
              <div class="item-text">Surveys</div>
              <div class="item-text">Secondary research</div>
              <div class="item-text">Market research</div>
            </div>
            <div class="list-item">
              <div class="item-title">Converge</div>
              <div class="item-text">Affinity diagram</div>
              <div class="item-text">Empathy map</div>
              <div class="item-text">User persona</div>
              <div class="item-text">Problem statement</div>
              <div class="item-text">User journey</div>
            </div>
            <div class="list-item">
              <div class="item-title">Diverge</div>
              <div class="item-text">Brainstorming</div>
              <div class="item-text">The worst idea possible</div>
              <div class="item-text">Value proposition</div>
              <div class="item-text">Sketching</div>
              <div class="item-text">Concept testing</div>
              <div class="item-text">Mid-fi prototyping</div>
              <div class="item-text">Hi-fi prototyping</div>
            </div>
            <div class="list-item">
              <div class="item-title">Converge</div>
              <div class="item-text">Heuristic evaluation</div>
              <div class="item-text">Usability testing</div>
              <div class="item-text">Desirability testing</div>
              <div class="item-text">Accessibility testing</div>
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Where did we begin?</div>
          <div class="subtitle">Choosing the right method</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                First, we thought that we should do a
                <strong
                  >survey to see which aspect of “living a healthy life” people
                  struggle with the most.</strong
                >
                This way we could scope down the wellness application topic can
                be used to
              </div>
              <div class="item-text">
                But given the <strong>timeline</strong> we had, the
                <strong>survey didn’t really fit</strong> in this stage of our
                project
              </div>

              <div class="item-text highlight">
                We then decided to find information to scope down, in a
                <strong>quicker way with secondary research</strong>
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/healthie/research.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-research" class="project-section">
          <div class="title">Which part of health?</div>
          <div class="subtitle">Secondary research</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                <strong>Our goal</strong> was to understand when do people need
                more help in order to live a healthier life
              </div>
              <div class="item-text">
                We (my 2 teammates and I) searched on the internet
                simultaneously
              </div>
              <div class="item-text">
                How to live a healthier life? The first 3 steps the
                <strong>WHO</strong> suggests are related to a
                <strong>healthy diet.</strong> Addiction and exercise follow
              </div>
              <div class="item-text">
                Also the <strong>pandemic</strong> affected people’s eating
                habits and there are
                <strong>more people interested in eating healthier</strong>
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/healthie/reading.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-subsection">
          <div class="subtitle">During the pandemic</div>
          <div id="impact">
            <div class="pull-up-1">
              <div class="impact">57%</div>
              <div>of French people <strong>Gained weight</strong></div>
            </div>
            <div class="pull-up-1">
              <div class="impact">56%</div>
              <div>
                of them consider
                <strong class="color-primary">eating healthier</strong>
              </div>
            </div>
            <div class="color-primary">
              We chose to <strong>focus on eating habits</strong>, not only
              because eating healthier is the first suggestion given by the WHO,
              but also it is a topic that seemed easier to have an impact on,
              compared to addiction and exercise.
            </div>
          </div>

          <div id="impact-mobile">
            <div class="item" style="padding-right: 0.5rem">
              <div class="impact">57%</div>
              <div>of French people <strong>Gained weight</strong></div>
            </div>
            <div class="item" style="padding-left: 0.5rem">
              <div class="impact">56%</div>
              <div>
                of them consider
                <strong class="color-primary">eating healthier</strong>
              </div>
            </div>
            <div class="highlight">
              We chose to focus on eating habits, not only because eating
              healthier is the first suggestion given by the WHO, but also it is
              a topic that seemed easier to have an impact on, compared to
              addiction and exercise.
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Before anything</div>
          <div class="subtitle">What is eating healthy?</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                According to the U.S Department of Health and Human Services,
                eating healthy means
                <strong
                  >following a healthy eating pattern that includes a variety of
                  nutritious foods and drinks.</strong
                >
                It also means
                <strong
                  >getting the number of calories that’s right for you</strong
                >
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/healthie/scale.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Do people eat healthy? Why not?</div>
          <div class="subtitle">User interviews</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                We need to understand what blocks people from eating healthier
                in order to help them achive it
              </div>
              <div class="item-text">
                The <strong>goal of the interview</strong> is to understand
                peoples perception of eating healthy, their behaviors, and
                struggles
              </div>
              <div class="item-text top-1">
                First, we brainstormed and wrote each question we thought of on
                our Figjam Board
              </div>
              <div class="item-text">
                We then identified which questions were more promising to
                <strong>get the most insight from the users.</strong> Biased
                questions were eliminated! We built better versions of our
                questions. Then made a logical order, introduction, thank you..
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/healthie/dialog.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-insights" class="project-section">
          <div class="title">What did they say?</div>
          <div class="subtitle">Insights, affinity diagram</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                We put together every piece of information we collected from our
                interviews
              </div>
              <div class="item-text top-1">
                With the affinity diagram, we could see that around “time” there
                was a big pattern. People were complaining about not having
                enough time to do what is necessary to eat healthier. Some
                people mentioned not having ideas about what to eat and also the
                price it takes to eat healthier.
              </div>
              <div class="item-text">
                Most of our interviewees were aligned on their definition of
                eating healthier. They were aware that a healthy diet meant a
                balanced diet.
              </div>
            </div>
            <div class="grid-image hide-mobile">
              <img
                src="../../assets/images/projects/healthie/time.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-subsection">
          <img src="../../assets/images/projects/healthie/postits.gif" alt="" />

          <div class="list-grid list-2">
            <div class="item-text highlight">
              Conclusion: <strong>time related problems</strong> are a
              <strong>big block</strong> for people to keep healthy eating
              habits
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Let’s double check that</div>
          <div class="subtitle">More secondary research</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                Before scoping down the topic one more time, we wanted to make
                sure that we were on a real problem by doing more secondary
                research.
              </div>
              <div class="item-text">
                We were mostly looking for the relationship between eating
                healthy and how busy people are.
              </div>
              <div class="item-text">
                French people think that
                <strong>thinking about what to eat is a hassle. </strong>
                <a
                  href="https://observatoirecetelem.com/app/uploads/sites/2/2019/11/rapport-harris-la-place-de-lalimentation-dans-la-vie-cetelem-1.pdf"
                  target="_blank"
                  >In this study</a
                >, <strong>45%</strong> of men and <strong>35%</strong> of women
                find
                <strong>choosing what to eat more inconvenient</strong> than the
                rest of the process.
              </div>
              <div class="item-text">
                Following the recommendations to eat healthier:
                <ul>
                  <li>Is difficult to follow every day</li>
                  <li>Makes grocery shopping more difficult</li>
                  <li>Makes people feel guilty when they don’t follow them</li>
                  <li>Takes away some part of the pleasure of eating.</li>
                </ul>
              </div>
            </div>
            <div class="only-not-mobile" style="grid-template-rows: 1fr auto">
              <div class="center">
                <img
                  class="side-image"
                  src="../../assets/images/projects/healthie/3-blockers.svg"
                  alt=""
                />
              </div>

              <div class="highlight">
                <div class="item-text">
                  <strong>
                    “How might we make it so that people can follow healthy
                    eating recommendations without spending too much time on it?
                    ”
                  </strong>
                </div>

                <div class="item-text">
                  if we help people
                  <strong>gain time while deciding,</strong> the rest of the
                  process could go smoother!
                </div>

                <div class="item-text">
                  Can this solution also be budget-friendly?
                </div>
              </div>
            </div>
            <div class="only-mobile" style="grid-template-rows: 1fr auto">
              <div class="highlight" style="margin-top: 0">
                <div class="item-text">
                  <strong>
                    “How might we make it so that people can follow healthy
                    eating recommendations without spending too much time on it?
                    ”
                  </strong>
                </div>

                <div class="item-text">
                  if we help people
                  <strong>gain time while deciding,</strong> the rest of the
                  process could go smoother!
                </div>

                <div class="item-text">
                  Can this solution also be budget-friendly?
                </div>
              </div>
              <div class="center top-2">
                <img
                  class="side-image"
                  src="../../assets/images/projects/healthie/3-blockers.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div id="section-surveys" class="project-section">
          <div class="title">Homemade food?</div>
          <div class="subtitle">Surveys</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                We needed to decide if we try to help people decide what to cook
                or what to order.
              </div>
              <div class="item-text top-1">
                We also thought it was a good opportunity to learn a little more
                about our competitors which would be helpful when we get to
                market analysis
              </div>
              <div class="item-text">
                We prepared a survey using Google forms and sent it out to
                people.
              </div>
              <div class="item-text">
                Our screener was “people who at least care about eating
                healthy”. Whether they managed or not didn’t matter because we
                could learn from both types of people.
              </div>
              <div class="item-text">
                <a
                  style="font-weight: bold"
                  href="https://forms.gle/zvaKkEAunoNgat1w7"
                  target="_blank"
                  >Here is our survey!</a
                >
              </div>
            </div>
            <div class="color-primary">
              <div class="item-text">
                Out of <strong>130</strong> french respondents,
                <strong>72%</strong> of them passed our screener
              </div>
              <div class="item-text">
                <strong>10%</strong> said that they used an
                <strong>application</strong> to get help for
                <strong>eating better</strong>
              </div>
              <div class="item-text">
                Out of the 90% of people who don’t use applications,
                <strong>40% never thought of using one</strong>, and
                <strong>26% never found the right application</strong>
              </div>
              <div class="item-text">
                People consider it is healthier to eat at home
              </div>
            </div>
          </div>
        </div>

        <div id="section-empathy" class="project-section">
          <div class="title">Let’s summarise all of this information</div>
          <div class="subtitle">Empathy map & User persona</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                There is
                <strong>a lot of information we need to remember</strong> during
                the rest of the process. To make things easier for us and for
                everybody elseto empathize with the users, we created our
                <strong>user persona</strong>, Benjamin.
              </div>
              <div class="item-text">
                Making an
                <a
                  style="font-weight: bold"
                  href="https://drive.google.com/file/d/1irpKXmLWi3HDf-XaVjkXlJaRkvJUNolf/view?usp=sharing"
                  target="_blank"
                  >empathy map</a
                >
                beforehand made creating our persona easier.
              </div>
              <div class="item-text top-1">
                He is 24 years old, a junior manager in a startup, he lives in
                Paris, big city life... He’s got many things he wants to do but
                <strong>not enough time.</strong> Amongst the things he wants to
                do, there is <strong>“having healthier eating habits”.</strong>
              </div>
              <div class="item-text top-1">
                He is <strong>frustrated</strong> because the whole eating
                healthy feels like a chore to him. He doesn’t want to spend so
                much time on it, and he still needs some help to decide what’s
                healthy or not. and Sometimes he compromises his free time after
                work in order to be able to eat <strong>homemade meals</strong>.
              </div>
            </div>
            <div class="grid-image">
              <img
                class="full"
                src="../../assets/images/projects/healthie/user-persona.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-journey" class="project-section">
          <div class="title">A close up of Benjamin’s journey</div>
          <div class="subtitle">User journey</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                After eating out lately, he decides to cook dinner for himself,
                something healthier this time.
              </div>
              <div class="item-text top-1">
                He decides to stop by the store on his way back from work. Going
                through the market, he doesn’t seem to think of a recipe that is
                both healthy and quick to make.
              </div>
              <div class="item-text">
                He goes on the internet looking for inspiration but ends up
                feeling overwhelmed.
              </div>
              <div class="item-text">
                In the end, he settles for a quick option: pasta. He feels
                <strong>disappointed and guilty</strong>
              </div>
            </div>
            <div class="color-primary">
              <div class="item-text">
                We decided to take the most difficult situation where people
                need to make decisions for meals, on the way or at the grocery
                store... If we can help people in these situations, we can help
                them the rest of the time too!
              </div>
              <div class="item-text">
                Benjamin in the current situation doesn’t achieve his goal.
              </div>
              <div class="item-text">
                <strong>How might we</strong> make it so, even
                <strong>at the last minute</strong>, Benjamin can make a
                <strong>quick and healthy decision</strong> on what to eat?
              </div>
            </div>
          </div>
        </div>

        <img
          id="journey-desktop"
          class="project-section"
          src="../../assets/images/projects/healthie/user-journey.png"
          alt=""
        />

        <img
          id="journey-mobile"
          class="top-2"
          src="../../assets/images/projects/healthie/journey-mobile.png"
          alt=""
        />

        <div id="section-problem" class="project-section">
          <div class="title">To make things more clear</div>
          <div class="subtitle">Problem statement</div>
          <div class="item-text">
            People who want to <strong>improve their eating habits</strong> need
            a way to make <strong>quick and healthy decisions</strong> on their
            diet because they often make
            <strong
              >unhealthy choices due to the lack of time and guidance.</strong
            >
          </div>
        </div>

        <div id="section-market" class="project-section">
          <div class="title">
            Who else is trying to solve a similar problem?
          </div>
          <div class="subtitle">Market analysis</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                What is out there already that aims to solve our users’ problem.
                What our users expect, what already works, and what is missing?
              </div>
              <div class="item-text">
                There are many applications that are related to healthy eating.
                Mostly focused on weight loss, meal planning, and recipe lists.
                To be able to get help from the existing applications, our users
                need to have more time dedicated to thinking about what to eat.
                But remember our problem statement? Benjamin is already at the
                grocery store!!
              </div>
              <div class="item-text top-1">
                Overall we tried to summarise this situation with a market
                positioning map. Our x-axis is to measure if the user’s problem
                is being solved immediately. And the y axis is showing if the
                application is calling people to be healthier or thinner.
              </div>
              <div class="item-text top-1">
                See our
                <a
                  href="https://docs.google.com/document/d/1vrRdLz9mt4l6WmeB_sx8Rz7cHQpcElChgrnBkQjK86c/edit?usp=sharing"
                  class="bold"
                  target="_blank"
                  >feature comparison</a
                >
              </div>
            </div>
            <img
              class="full"
              src="../../assets/images/projects/healthie/market-position.png"
              alt=""
            />
          </div>
        </div>

        <div class="project-section">
          <div class="title">We have some ideas but we are only designers</div>
          <div class="subtitle">SME Interviews</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                Knowing the exact spot we stand, we already started having some
                general ideas Before we dived deep into ideating we talked with
                a subject matter expert in nutrition programs
              </div>
              <div class="item-text">
                Our interview with the SME went more like a concept test. We
                explained to him the problem and some of our ideas and listened
                to his advice
              </div>
              <div class="item-text">
                One good way would be to help them find a healthier version of
                what they already like to eat.
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/healthie/sitting-reading.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div id="section-brainstorm" class="project-section">
          <div class="title">Closer to the solution</div>
          <div class="subtitle">Brainstorming & results</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                A free environment to come up with many ideas, the only
                constraint is to help Benjamin who doesn’t wanna go back home to
                eat pasta.
              </div>
              <div class="item-text">
                Just to make sure that we used all of our creativeness capacity,
                we also used the worst idea possible ideation method. ( It was
                so fun)
              </div>
              <div class="item-text">
                We had a lot of ideas but we were building an MVP. How to choose
                the most relevant ideas? We filled our value proposition canvas.
                We already knew our user’s needs, and the values our application
                proposes would simply match the user’s needs.
              </div>
            </div>
            <div class="color-primary">
              <div class="item-text">
                At the end of all the ideating methods we used, we came to a
                conclusion and had our set of MUST HAVES:
              </div>
              <div class="item-text top-2">
                Healthy recipes that can be filtered according to users’ needs (
                time, budget, food preferences)
              </div>
              <div class="item-text top-1">
                Recipe details that highlight different information personalized
                to each user.
              </div>
              <div class="item-text top-1">
                Profile where users can save their food preferences and their
                current nutritional profile.
              </div>
              <div class="item-text top-1">
                Grocery list created automatically through the recipes.
              </div>
              <div class="item-text top-1">
                Dashboard to keep track of the progress
              </div>
              <div class="item-text top-1">
                Educational components to help users learn more
              </div>
              <div class="item-text top-1">
                Keep users motivated, remind them healthier doesn’t mean perfect
              </div>
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Before it gets serious</div>
          <div class="subtitle">Sketching</div>
          <div class="list-grid list-2-3">
            <div>
              <div class="item-text">
                My 2 teammates and I set a timer to sketch as many variations as
                we can for each must-have that we decided.
              </div>
              <div class="item-text">
                Later we explained to each other our ideas. Throughout the
                process, we always followed a “project first” approach. At this
                stage too, it was easy for us to choose the most relevant parts
                from each other’s sketches and add our ideas on top of each
                other!
              </div>
              <div class="item-text top-1">
                Below I added some of my sketches
              </div>
            </div>
          </div>
        </div>

        <div class="top-1">
          <img
            src="../../assets/images/projects/healthie/sketches.png"
            alt=""
          />
        </div>

        <div id="section-moodboard" class="project-section">
          <div class="title">How should the interface feel?</div>
          <div class="subtitle">Moodboard</div>
          <div class="list-grid list-2">
            <div class="item-text">
              Collecting some feelings and visuals that corresponds to those
              attributes
            </div>
          </div>
        </div>

        <div class="top-1">
          <img
            class="full"
            src="../../assets/images/projects/healthie/moodboard-desktop.png"
            alt=""
          />
        </div>

        <div class="project-section">
          <div class="title">Sneak peek</div>
          <div class="subtitle">Style tile</div>
          <div class="list-grid list-2-3">
            <div class="item-text">
              Trying to stay friendly with users, we went with more round icons
              and typography.<br />
              Green is an expected color from users when it comes to health
              related applications.
            </div>
          </div>
        </div>

        <div class="top-1">
          <img
            id="styles-desktop"
            class="full"
            src="../../assets/images/projects/healthie/style-tile-desktop.png"
            alt=""
          />

          <img
            id="styles-mobile"
            class="full"
            src="../../assets/images/projects/healthie/style-tile-mobile.png"
            alt=""
          />
        </div>

        <div id="section-hifi">
          <div id="prototype" class="project-section">
            <div class="title">Ta-daa</div>
            <div class="subtitle">Hi-fi prototype</div>
            <div class="list-grid list-2-3">
              <div>
                <div class="item-text">
                  Let’s remember Benjamin again, and his little story about
                  going back home and stopping by the grocery store. Right
                  before he bought pasta, he now opens our application.
                </div>
                <div class="item-text">
                  You can interact with the prototype below
                </div>
              </div>
            </div>
            <div class="top-1">
              <iframe
                style="border: 1px solid rgba(0, 0, 0, 0.1)"
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FZAsvThhA8QwG3osAxxnaRK%2FHealthie-%257C-Ironhack-project%3Fpage-id%3D909%253A25064%26node-id%3D909%253A25128%26viewport%3D269%252C48%252C0.07%26scaling%3Dscale-down%26starting-point-node-id%3D909%253A25128"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div id="solution" class="project-section">
          <div class="title">What does HEALTHIE do?</div>
          <div class="subtitle">Our solution</div>
          <div
            class="size-desktop list-grid images-grid"
            style="grid-template-columns: repeat(3, 1fr)"
          >
            <div class="list-flex flex-column">
              <div class="item-container">
                <div class="item-title">Healthy recipes</div>
                <div class="item-text">
                  We provide recipes to make it easier and faster for users to
                  make healthy decisions
                </div>
              </div>
              <div class="item-container">
                <div class="item-title">Tailor fit recipies</div>
                <div class="item-text">
                  Users can set food preferences, some nutritional needs in
                  their profile, this way all the recipes will already be
                  filtered according to their needs.
                </div>
              </div>
              <div class="item-container">
                <div class="item-title">Easy to search</div>
                <div class="item-text">
                  We created 3 main filters. Time, price, healthiness level of
                  the recipes. <strong>Remember the 3 main blockers?</strong>
                </div>
              </div>
            </div>
            <img
              src="../../assets/images/projects/healthie/home-page.png"
              alt=""
            />
            <img
              src="../../assets/images/projects/healthie/preferences-page.png"
              alt=""
            />
          </div>
          <div class="size-medium">
            <div class="descriptions">
              <div class="item-container">
                <div class="item-title">Healthy recipes</div>
                <div class="item-text">
                  We provide recipes to make it easier and faster for users to
                  make healthy decisions
                </div>
              </div>

              <div class="item-container">
                <div class="item-title">Tailor fit recipies</div>
                <div class="item-text">
                  Users can set food preferences, some nutritional needs in
                  their profile, this way all the recipes will already be
                  filtered according to their needs.
                </div>
              </div>

              <div class="item-container">
                <div class="item-title">Easy to search</div>
                <div class="item-text">
                  We created 3 main filters. Time, price, healthiness level of
                  the recipes. <strong>Remember the 3 main blockers?</strong>
                </div>
              </div>
            </div>
            <div class="images">
              <img
                src="../../assets/images/projects/healthie/home-page.png"
                alt=""
              />

              <img
                src="../../assets/images/projects/healthie/preferences-page.png"
                alt=""
              />
            </div>
          </div>
          <div class="size-mobile">
            <div class="item-container">
              <div class="item-title">Healthy recipes</div>
              <div class="item-text">
                We provide recipes to make it easier and faster for users to
                make healthy decisions
              </div>
            </div>

            <div class="item-container">
              <div class="item-title">Easy to search</div>
              <div class="item-text">
                We created 3 main filters. Time, price, healthiness level of the
                recipes. <strong>Remember the 3 main blockers?</strong>
              </div>
            </div>

            <div class="item-container">
              <img
                src="../../assets/images/projects/healthie/home-page.png"
                alt=""
              />
            </div>
            <div class="item-container">
              <div class="item-title">Tailor fit recipies</div>
              <div class="item-text">
                Users can set food preferences, some nutritional needs in their
                profile, this way all the recipes will already be filtered
                according to their needs.
              </div>
            </div>
            <div class="item-container">
              <img
                src="../../assets/images/projects/healthie/preferences-page.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">What does HEALTHIE do?</div>
          <div class="subtitle">Our solution</div>
          <div
            class="size-desktop list-grid images-grid"
            style="grid-template-columns: repeat(3, 1fr)"
          >
            <div class="list-flex flex-column">
              <div class="item-container">
                <div class="item-title">Easier to choose</div>
                <div class="item-text">
                  Each recipe information is organized according to users’
                  needs. Instead of telling user this meal has 20gr protein, we
                  tell them
                  <strong
                    >how much of their daily intake it corresponds to.</strong
                  >
                </div>
              </div>
              <div class="item-container">
                <div class="item-title">Easy to shop</div>
                <div class="item-text">
                  Create automatic grocery list from chosen recipes. This way,
                  we tried to help them make the rest of the eating healthy
                  process go faster
                </div>
              </div>
            </div>
            <img
              src="../../assets/images/projects/healthie/recipe-page.png"
              alt=""
            />
            <img
              src="../../assets/images/projects/healthie/grocery-page.png"
              alt=""
            />
          </div>
          <div class="size-medium">
            <div class="descriptions">
              <div class="item-container">
                <div class="item-title">Easier to choose</div>
                <div class="item-text">
                  Each recipe information is organized according to users’
                  needs. Instead of telling user this meal has 20gr protein, we
                  tell them
                  <strong
                    >how much of their daily intake it corresponds to.</strong
                  >
                </div>
              </div>
              <div class="item-container">
                <div class="item-title">Easy to shop</div>
                <div class="item-text">
                  Create automatic grocery list from chosen recipes. This way,
                  we tried to help them make the rest of the eating healthy
                  process go faster
                </div>
              </div>
            </div>
            <div class="images">
              <img
                src="../../assets/images/projects/healthie/recipe-page.png"
                alt=""
              />
              <img
                src="../../assets/images/projects/healthie/grocery-page.png"
                alt=""
              />
            </div>
          </div>
          <div class="size-mobile">
            <div class="item-container">
              <div class="item-title">Easier to choose</div>
              <div class="item-text">
                Each recipe information is organized according to users’ needs.
                Instead of telling user this meal has 20gr protein, we tell them
                <strong
                  >how much of their daily intake it corresponds to.</strong
                >
              </div>
            </div>

            <div class="item-container">
              <img
                src="../../assets/images/projects/healthie/recipe-page.png"
                alt=""
              />
            </div>
            <div class="item-container">
              <div class="item-title">Easy to shop</div>
              <div class="item-text">
                Create automatic grocery list from chosen recipes. This way, we
                tried to help them make the rest of the eating healthy process
                go faster
              </div>
            </div>
            <div class="item-container">
              <img
                src="../../assets/images/projects/healthie/grocery-page.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Easier to search but how?</div>
          <div class="subtitle">Reasoning behind main filters</div>

          <div class="size-desktop">
            <div class="list-grid" style="grid-template-columns: 1fr 1fr">
              <div class="item-intro">
                Each filter is easy to use, slider for time and one tap changes
                between 3 options for health and budget. We considered our user
                persona trying to decide what to eat on his way home.
              </div>
            </div>

            <div
              class="list-grid images-grid"
              style="grid-template-columns: 1fr 1fr 2fr"
            >
              <div class="list-flex flex-column">
                <div class="item-container">
                  <div class="item-title">Healthy filter</div>
                  <div class="item-text">
                    Healthy filter has 3 levels. Healthy, healthier and
                    healthiest.
                  </div>
                  <div class="item-text">
                    We wanted users to have balanced diet.
                  </div>
                  <div class="item-text">
                    SME told us the best way is:
                    <strong
                      >find what they like and make healthier versions.</strong
                    >
                  </div>
                  <div class="item-text">
                    With this filter, we aimed to
                    <strong
                      >create some kind of food category that goes between
                      familiar but less healthy and new & crazy healthy recipes.
                    </strong>
                  </div>
                  <div class="item-text">
                    This way users can feel more free while making healthier
                    choices with each filter
                  </div>
                </div>
              </div>
              <div class="list-flex flex-column">
                <div class="item-container">
                  <div class="item-title">Time filter</div>
                  <div class="item-text">
                    Users can easily slide to the time that they have, so they
                    only need to choose between the realistic recipe options
                    according to their situation.
                  </div>
                </div>
                <div class="item-container">
                  <div class="item-title">Budget filter</div>
                  <div class="item-text">
                    We could not and did not want to give users the prices of
                    everything but an idea of price too since budget was also a
                    blocker.
                  </div>
                </div>
              </div>
              <img
                src="../../assets/images/projects/healthie/filters-page.png"
                alt=""
              />
            </div>
          </div>

          <div class="size-medium">
            <div class="item-container">
              <div class="item-title">Healthy filter</div>
              <div class="item-text">
                Healthy filter has 3 levels. Healthy, healthier and healthiest.
              </div>
              <div class="item-text">
                We wanted users to have balanced diet.
              </div>
              <div class="item-text">
                SME told us the best way is:
                <strong
                  >find what they like and make healthier versions.</strong
                >
              </div>
              <div class="item-text">
                With this filter, we aimed to
                <strong
                  >create some kind of food category that goes between familiar
                  but less healthy and new & crazy healthy recipes.
                </strong>
              </div>
              <div class="item-text">
                This way users can feel more free while making healthier choices
                with each filter
              </div>
            </div>
            <div class="item-container">
              <div class="item-title">Time filter</div>
              <div class="item-text">
                Users can easily slide to the time that they have, so they only
                need to choose between the realistic recipe options according to
                their situation.
              </div>
            </div>
            <div class="item-container">
              <div class="item-title">Budget filter</div>
              <div class="item-text">
                We could not and did not want to give users the prices of
                everything but an idea of price too since budget was also a
                blocker.
              </div>
            </div>

            <div class="images">
              <img
                src="../../assets/images/projects/healthie/filters-page-1.png"
                alt=""
              />
              <img
                src="../../assets/images/projects/healthie/filters-page-2.png"
                alt=""
              />
            </div>
          </div>

          <div class="size-mobile">
            <div class="item-container">
              <div class="item-title">Healthy filter</div>
              <div class="item-text">
                Healthy filter has 3 levels. Healthy, healthier and healthiest.
              </div>
              <div class="item-text">
                We wanted users to have balanced diet.
              </div>
              <div class="item-text">
                SME told us the best way is:
                <strong
                  >find what they like and make healthier versions.</strong
                >
              </div>
              <div class="item-text">
                With this filter, we aimed to
                <strong
                  >create some kind of food category that goes between familiar
                  but less healthy and new & crazy healthy recipes.
                </strong>
              </div>
              <div class="item-text">
                This way users can feel more free while making healthier choices
                with each filter
              </div>
            </div>
            <div class="item-container">
              <div class="item-title">Time filter</div>
              <div class="item-text">
                Users can easily slide to the time that they have, so they only
                need to choose between the realistic recipe options according to
                their situation.
              </div>
            </div>
            <div class="item-container">
              <div class="item-title">Budget filter</div>
              <div class="item-text">
                We could not and did not want to give users the prices of
                everything but an idea of price too since budget was also a
                blocker.
              </div>
            </div>
            <div class="item-container">
              <img
                src="../../assets/images/projects/healthie/filters-page-1.png"
                alt=""
              />
            </div>
            <div class="item-container">
              <img
                src="../../assets/images/projects/healthie/filters-page-2.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="project-section">
          <div class="title">Some Backstage</div>
          <div class="subtitle">Mid-fi</div>
          <div class="list-grid list-4">
            <img
              class="full"
              src="../../assets/images/projects/healthie/midfi-1.png"
              alt=""
            />
            <img
              class="full"
              src="../../assets/images/projects/healthie/midfi-2.png"
              alt=""
            />
            <img
              class="full"
              src="../../assets/images/projects/healthie/midfi-3.png"
              alt=""
            />
            <img
              class="full"
              src="../../assets/images/projects/healthie/midfi-4.png"
              alt=""
            />
          </div>
        </div>

        <div id="section-usability" class="project-section">
          <div class="title">What did users think?</div>
          <div class="subtitle">Usability test</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                We conducted usability test with 5 people.
                <strong>Health filter wasn’t so obvious</strong> to people. Even
                when we changed it to text, they needed
                <strong>more indication!</strong>
              </div>
              <div class="item-text">
                Without the onboarding, users got confused about the goal of our
                application, so in the search bar we changed our placeholder to
                indicate that we are showing recipes.
              </div>
            </div>
            <div>
              <div class="item-text">
                We
                <strong>prioritized certain information on recipe pages</strong>
                to make the <strong>desicion making quicker</strong> , and large
                enough to make it accessible in different situations.
              </div>
              <div class="item-text">
                Some users thought the recipe explanation steps were located too
                low on the page.
              </div>
              <div class="item-text color-primary">
                If they arrive there from the grocery list, we concider it as
                they already made a decision and now is the time to cook, so, we
                positioned the recipe steps on top of the page
              </div>
            </div>
          </div>
        </div>

        <img
          id="usability-test-desktop"
          class="top-2"
          src="../../assets/images/projects/healthie/usability-desktop.png"
          alt=""
        />

        <img
          id="usability-test-mobile"
          class="top-2"
          src="../../assets/images/projects/healthie/usability-test-mobile.png"
        />

        <div class="project-section">
          <div class="title">If we had 1 more day</div>
          <div class="subtitle">Next steps</div>
          <div class="list-grid list-2">
            <div>
              <div class="item-text">
                Considering the food they log in our application, offer them
                what else they might need
              </div>
              <div class="item-text">
                Features that include sharing with other people
              </div>
              <div class="item-text">
                Multiple recipe selection for grocery list
              </div>
            </div>
            <div class="grid-image">
              <img
                src="../../assets/images/projects/healthie/ballet.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projectHealthie } from "@/helpers/content.js";
import variables from "@/assets/scss/variables.scss";
import ScrollButton from "@/components/ScrollButton.vue";
export default {
  name: "Healthie",
  components: {
    ScrollButton,
  },
  data() {
    return {
      project: projectHealthie,
      // ! Sections need to be in the same order in this.scections and in the html
      sections: [
        {
          name: "section-client",
          title: "Our fictional client",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-research",
          title: "Secondary research",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-insights",
          title: "Insights, affinity diagram",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-surveys",
          title: "Surveys",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-empathy",
          title: "Empathy map & User persona",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-journey",
          title: "User journey",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-problem",
          title: "Problem statement",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-market",
          title: "Market analysis",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-brainstorm",
          title: "Brainstorming & results",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-moodboard",
          title: "Moodboard",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-hifi",
          title: "Hi-fi prototype",
          action: "Jump to",
          offset: null,
        },
        {
          name: "section-usability",
          title: "Usability test",
          action: "Jump to",
          offset: null,
        },
      ],
      nextSection: {
        name: "section-client",
        title: "Our fictional client",
        action: "Jump to",
        offset: null,
      },
      topSection: {
        name: "top",
        title: "Top",
        action: "Back to",
      },
      variables,
      prevTime: Date.now(),
      scrollTimeout: null,
    };
  },
  created() {
    window.addEventListener("scroll", this.scrollHandler);
  },
  mounted() {
    this.scrollTop();
    this.setNextSection();
  },
  methods: {
    scrollHandler() {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.setNextSection();
      }, 100);
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    scrollTo() {
      if (this.nextSection.name === "top") {
        this.scrollTop();
      } else {
        const el = document.getElementById(this.nextSection.name);
        window.scrollTo({ top: el.offsetTop, behavior: "smooth" });
      }
      this.setNextSection();
    },
    setNextSection() {
      this.sections.forEach((section) => {
        const el = document.getElementById(section.name);
        section.offset = el.offsetTop;
      });
      let next;
      for (const section of this.sections) {
        if (window.scrollY + 5 < section.offset) {
          next = section.name;
          break;
        }
      }
      const totalScroll = window.scrollY + window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const errorMargin = 200;
      console.log("next", next);
      if (totalScroll + errorMargin >= documentHeight) {
        this.nextSection = this.topSection;
        return;
      }
      if (next) {
        this.nextSection = this.sections.find(
          (section) => section.name === next
        );
      } else {
        this.nextSection = this.topSection;
      }
    },
  },
  computed: {
    scrollText() {
      return this.nextSection.action + " " + this.nextSection.title;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/project.scss";

#background {
  background-color: $color-healthie-bg;
}
.main-title {
  color: $color-healthie-primary;
}
.main-subtitle {
  color: $color-healthie-secondary;
}
.item-title {
  color: $healthie-primary;
}
.highlight {
  color: $healthie-primary;
}
.color-primary {
  color: $healthie-primary;
}
</style>
