<template>
  <div class="scroll-button">{{ text }}</div>
</template>
<script>
export default {
  name: "ScrollButton",
  props: {
    text: {
      type: String,
      default: "Top",
    },
  },
};
</script>
<style scoped lang="scss">
.scroll-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: fit-content;
  font-size: 1rem;
  z-index: 200;
  font-weight: 300;
  border-radius: 200px;
  padding: 0.5rem 1rem;
  background: #0c0c0c;
  cursor: pointer;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.5);
}
</style>
