<template>
  <div>
    <div id="background"></div>
    <div id="ux-ui" class="full-page">
      <div class="main-container">
        <div class="container-top">
          <div class="content">
            <div class="main-title">UX/UI</div>
            <div class="description">
              <div>
                Coming from
                <strong>industrial design, my curiosity for HTML & CSS</strong>
                made me decide to change my career.
              </div>
              <div>
                I discovered my passion for
                <strong
                  >UX/UI design while I was learning front-end
                  development.</strong
                >
              </div>
              <div>
                As much as I enjoyed coding, I found myself
                <strong
                  >more excited about defining problems and finding
                  user-centered solutions</strong
                >
                through digital products
              </div>
            </div>
            <div class="description">
              <div>
                This realization made me decide to take
                <strong>a real step towards UX/UI.</strong>
              </div>
              <div>
                I took <strong>Figma and UI courses</strong> and finally by
                joining <strong>Ironhack France</strong>, I learned all about
                UX/UI and got to put what I learned into practice by completing
                5 projects in 9 weeks
              </div>
            </div>
          </div>
        </div>
        <div class="container-bottom">
          <img id="hand" />
        </div>
      </div>
    </div>
    <div id="my-skills">
      <div class="main-container">
        <div class="container-top">
          <div class="content">
            <div class="main-title">My skills</div>
            <div class="skills-container only-desktop">
              <PillText
                v-for="(skill, i) of skillsDesktop"
                :key="i"
                :content="skill.text"
                :background="variables.skillsPillBg"
              />
            </div>
            <div class="skills-container only-mobile">
              <PillText
                v-for="(skill, i) of skillsMobile"
                :key="i"
                :content="skill.text"
                :background="variables.skillsPillBg"
              />
            </div>
          </div>
        </div>
        <div class="container-bottom">
          <Wave :color="variables.beforeMainBg" class="only-desktop" />
          <Wave
            :color="variables.beforeMainBg"
            view-box="0 0 1440 320"
            class="only-mobile"
          />
        </div>
      </div>
    </div>
    <div id="before-ux" class="full-page">
      <div class="main-container">
        <div class="container-top">
          <div class="content">
            <div class="main-title">Before UX/UI</div>
            <div class="description">
              <div>I have always had this creative side of me</div>
              <div>
                Had notebooks full of clothes designs at middle school, I even
                made prototypes of my sketches using what I had around me: my
                socks and scissors.
              </div>
              <div>
                My parents catching on this side of me, I studied fine arts at
                high school. Gained a scholarship to study product design at
                university
              </div>
            </div>
            <div class="description">
              <div>
                Right after graduation, I started working as a product/interior
                designer. Moving to France, I studied French at Sorbonne and did
                some part time & freelance jobs.
              </div>
              <div>
                Due to my interest for frontend development, I took a career
                break to learn coding.
              </div>
              <div>
                I followed several online courses and practiced coding building
                some projects at the same time.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import variables from "@/assets/scss/variables.scss";
import { aboutSkillsArr } from "@/helpers/content.js";
import PillText from "@/components/PillText.vue";
import Wave from "@/components/Wave.vue";
export default {
  name: "About",
  components: {
    Wave,
    PillText,
  },
  data() {
    return {
      variables,
      skills: aboutSkillsArr,
    };
  },
  computed: {
    skillsMobile() {
      return this.skills.filter((skill) => skill.device !== "desktop");
    },
    skillsDesktop() {
      return this.skills.filter((skill) => skill.device !== "mobile");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/variables.scss";
.full-page {
  min-height: calc(100vh - 3rem);
  height: auto;
  display: flex;
}
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.container-top {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 4rem 2rem 0 2rem;
}
.container-bottom {
  display: flex;
  align-items: baseline;
}
.description {
  margin-bottom: 1rem;
  div {
    margin-bottom: 0.5rem;
  }
  line-height: 1.7rem;
}
.main-title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 2rem;
}
.pill-text {
  width: 100%;
  max-width: 275px;
  font-weight: 600;
}
#background {
  background-color: $ux-main-bg;
  height: 200px;
}
#ux-ui {
  background-color: $ux-main-bg;
  color: $text-black;
}
#before-ux {
  background-color: $before-main-bg;
  color: $before-main-text;
  .main-title {
    color: $before-main-text;
  }
}
#my-skills {
  background: $skills-main-bg;
  color: $skills-main-text;
  .main-container {
    min-height: 100vh;
  }
  .main-title {
    color: $skills-main-text;
  }
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;
}

#footer {
  margin-top: 0;
}
#hand {
  content: url("../assets/images/skills/skills-hand-mobile.svg");
  width: 100%;
  position: relative;
  top: 2px;
}

@media screen and (min-width: $screen-mobile) {
  .main-container {
    text-align: center;
  }

  .container-top {
    align-items: center;
    padding: 0;
    .content {
      width: 80%;
    }
  }

  .skills-container {
    margin-bottom: 0;
  }

  .pill-text {
    width: auto;
    max-width: none;
  }
}

@media screen and (min-width: $screen-medium) {
  #hand {
    content: url("../assets/images/skills/skills-hand.svg");
  }
}
</style>
