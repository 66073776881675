<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox">
    <path
      :fill="color"
      fill-opacity="1"
      d="M0,160L48,149.3C96,139,192,117,288,96C384,75,480,53,576,74.7C672,96,768,160,864,170.7C960,181,1056,139,1152,122.7C1248,107,1344,117,1392,122.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Wave",
  props: {
    color: {
      type: String,
      default: "",
    },
    viewBox: {
      type: String,
      default: "0 0 1440 200",
    },
  },
};
</script>

<style scoped>
svg {
  display: block;
}
</style>
